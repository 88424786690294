<template>
    <div>
        <app-bar :title="t('statistic_title')"></app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <card-collection
                :meta="meta"
                :busy="overlay"
                @updated="updated"
            >
                <template #filters>
                    <v-toolbar
                        elevation="0"
                        color="transparent"
                        class="mt-7"
                        height="56"
                    >
                        <v-text-field
                            v-model="queries.search"
                            @click:clear="clear"
                            class="input-search md mr-2"
                            clearable
                            outlined
                            dense
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            :label="t('general_label_search')"
                        ></v-text-field>

                        <span class="sub-label px-5">{{ t('general_label_date_period') }}</span>

                        <date-picker-modal
                            :value="queries.date_from"
                            @updated="(v) => queries.date_from = v"
                        >
                            <template #default="{ on, attrs }">
                                <div
                                    v-on="on"
                                    v-bind="attrs"
                                >
                                    <v-text-field
                                        v-model="queries.date_from"
                                        class="xs input-color-inherit"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        :label="t('general_label_from')"
                                    ></v-text-field>
                                </div>
                            </template>
                        </date-picker-modal>

                        <span class="sub-label px-2">-</span>

                        <date-picker-modal
                            :value="queries.date_to"
                            @updated="(v) => queries.date_to = v"
                        >
                            <template #default="{ on, attrs }">
                                <div
                                    v-on="on"
                                    v-bind="attrs"
                                >
                                    <v-text-field
                                        v-model="queries.date_to"
                                        class="xs input-color-inherit"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        :label="t('general_label_to')"
                                    ></v-text-field>
                                </div>
                            </template>
                        </date-picker-modal>

                        <v-select
                            v-model="queries.gender"
                            :items="[ { text: '-', value: '' } ].concat(genders())"
                            class="md mx-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('user_label_gender')"
                        ></v-select>

                        <v-select
                            v-model="queries.level"
                            :items="[ { text: '-', value: '' } ].concat(levels())"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            :label="t('user_label_level')"
                        ></v-select>

                        <v-select
                            v-model="queries.activity_providers"
                            :items="activityProviders(true)"
                            class="md mr-2"
                            outlined
                            dense
                            append-icon="mdi-chevron-down"
                            hide-details
                            multiple
                            :label="t('user_label_provider')"
                        ></v-select>

                        <v-btn
                            @click="search"
                            class="sm mx-5"
                            color="primary"
                        >{{ t('general_action_search') }}</v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                            v-ripple="false"
                            plain
                            color="orange darken-4"
                            @click="reset"
                        >{{ t('general_action_reset_filters') }}</v-btn>

                    </v-toolbar>
                </template>

                <template #data>
                    <thead>
                    <tr>
                        <th class="touched">
                            <sort-field
                                v-model="queries.sort"
                                field="id"
                                @touched="onSort"
                            >{{ t('user_label_id') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="last_name"
                                @touched="onSort"
                            >{{ t('user_label_last_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="first_name"
                                @touched="onSort"
                            >{{ t('user_label_first_name') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="email"
                                @touched="onSort"
                            >{{ t('user_label_email_address') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="registered_at"
                                @touched="onSort"
                            >{{ t('user_label_registration_date') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="gender"
                                @touched="onSort"
                            >{{ t('user_label_gender') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="level"
                                @touched="onSort"
                            >{{ t('user_label_level') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="total_distance"
                                @touched="onSort"
                            >{{ t('statistic_label_total_distance') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="total_time"
                                @touched="onSort"
                            >{{ t('statistic_label_total_duration') }}</sort-field>
                        </th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="workout_executions_count"
                                @touched="onSort"
                            >{{ t('statistic_label_workout_executions') }}</sort-field>
                        </th>
                        <th>{{ t('user_label_provider') }}</th>
                        <th>
                            <sort-field
                                v-model="queries.sort"
                                field="active_at"
                                @touched="onSort"
                            >{{ t('user_label_active_at') }}</sort-field>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="collection.data.length === 0">
                        <td class="text-center" colspan="11">{{ t('general_text_no_data') }}</td>
                    </tr>
                    <template v-else v-for="user in collection.data">
                        <tr>
                            <td>{{ user.id }}</td>
                            <td>{{ user.lastName }}</td>
                            <td>{{ user.firstName }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.registeredAt.format('DD/MM/YYYY') }}</td>
                            <td>{{ genderTitle(user.gender) }}</td>
                            <td>{{ levelTitle(user.level) }}</td>
                            <td>{{ user._totalDistance }}</td>
                            <td>{{ user._totalTime }}</td>
                            <td>{{ user.workoutExecutionsCount }}</td>
                            <td style="white-space:nowrap">{{ activityProvidersStrTitle(user.activityProviders) }}</td>
                            <td>{{ user.activeAt ? user.activeAt.format('DD/MM/YYYY HH:mm') : '-' }}</td>
                        </tr>
                    </template>
                    </tbody>
                </template>
                <template #export>
                    <v-btn
                        @click="_export"
                        class="exs mt-4 ml-4"
                        color="primary"
                        small
                    >{{ t('general_action_export') }}</v-btn>
                </template>
            </card-collection>
        </v-layout>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onMounted, toRefs, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useStore } from '@/store'
import { useEnums } from '@/core/defaults'
import { useLocales } from '@/core/defaults'
import { useUserStatisticHttp } from '@/core/api/user-statistics'
import { UserStatisticCollection } from '@/store/modules/user-statistic-collection'
import DatePickerModal from '@/components/DatePickerModal.vue'
import CardCollection from '@/components/CardCollection.vue'
import SortField from '@/components/SortField.vue'

export default defineComponent({
    components: {
        DatePickerModal,
        CardCollection,
        SortField,
    },

    /**
     * @param props
     */
    setup(props) {
        const state = reactive({
            overlay: true,
            meta: undefined
        })

        const queries = reactive({
            search: undefined,
            page: 1,
            per_page: 20,
            sort: '-id',
            date_from: undefined,
            date_to: undefined,
            gender: undefined,
            level: undefined,
            activity_providers: <any>undefined,
            status: 'active',
        })

        const reset = () => {
            queries.search = undefined
            queries.page = 1
            queries.per_page = 20
            queries.sort = '-id'
            queries.date_from = undefined
            queries.date_to = undefined
            queries.gender = undefined
            queries.level = undefined
            queries.activity_providers = undefined
            capture()
        }

        const store = useStore()

        const collection = computed(() => <UserStatisticCollection>store.getters['userStatisticCollection/get'])

        const updated = (updatedQueries: any) => {
            queries.page = updatedQueries.page
            capture()
        }

        const capture = () => {
            state.overlay = true
            let payload = <any>{}
            Object.assign(payload, queries)
            if (queries.activity_providers instanceof Array) {
                payload.activity_providers = queries.activity_providers.join(',')
            }
            store.dispatch('userStatisticCollection/get', payload).then((data) => {
                state.meta = data.meta
                state.overlay = false
            })
        }

        const _export = () => {
            state.overlay = true
            let payload = <any>{}
            Object.assign(payload, queries)
            if (queries.activity_providers instanceof Array) {
                payload.activity_providers = queries.activity_providers.join(',')
            }
            useUserStatisticHttp()._export(payload).then((data: any) => {
                state.overlay = false
                const e = document.createElement('a')
                e.href = window.URL.createObjectURL(data)
                e.setAttribute('download', 'User statistics.csv')
                document.body.appendChild(e)
                e.click()
            })
        }

        const search = () => {
            capture()
        }

        const clear = () => {
            queries.search = undefined
            capture()
        }

        const onSort = (value: any) => {
            if (value === undefined) {
                value = '-id'
            }
            queries.sort = value
        }

        watch(() => [
            queries.sort,
            queries.gender,
            queries.level,
        ], () => {
            capture()
        })

        onMounted(() => {
            capture()
        })

        return {
            queries,
            collection: collection.value,
            _export,
            updated,
            onSort,
            search,
            clear,
            reset,
            ...useI18n(),
            ...useEnums(),
            ...useLocales(),
            ...toRefs(state)
        }
    }
})
</script>
