
<div>
    <app-bar :title="t('params_title')"></app-bar>

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <card-collection
            :meta="meta"
            :busy="overlay"
            @updated="updated"
        >
            <template #filters>
                <v-toolbar
                    elevation="0"
                    color="transparent"
                    class="mt-7"
                    height="56"
                >
                    <v-select
                        v-model="queries.type"
                        :items="[ { text: '-', value: '' } ].concat(paramTypes())"
                        class="md mr-5"
                        outlined
                        dense
                        append-icon="mdi-chevron-down"
                        hide-details
                        :label="t('param_label_type')"
                    ></v-select>

                    <v-text-field
                        v-model="queries.search"
                        @click:clear="clear"
                        class="input-search md"
                        clearable
                        outlined
                        dense
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        :label="t('general_label_search')"
                    ></v-text-field>

                    <v-btn
                        @click="search"
                        class="sm mx-5"
                        color="primary"
                    >{{ t('general_action_search') }}</v-btn>

                    <v-btn
                        v-ripple="false"
                        plain
                        color="orange darken-4"
                        @click="reset"
                    >{{ t('general_action_reset_filters') }}
                    </v-btn>
                </v-toolbar>
            </template>

            <template #data>
                <thead>
                <tr>
                    <th>{{ t('param_label_key') }}</th>
                    <th>{{ t('param_label_value') }}</th>
                    <th>{{ t('param_label_type') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="collection.data.length === 0">
                    <td class="text-center" colspan="2">{{ t('general_text_no_data') }}</td>
                </tr>
                <template v-else v-for="param in collection.data">
                    <tr>
                        <td class="titled customized-deep-primary--text">{{ param.param_key }}</td>
                        <td>
                            <param-modal :value="param">
                                <template #default="{ on, attrs }">
                                    <a
                                        class="link-bordered"
                                        v-on="on"
                                        v-bind="attrs"
                                        href="javascript:"
                                    >
                                        {{ param.param_value || '-' }}
                                    </a>
                                </template>
                            </param-modal>
                        </td>
                        <td class="titled customized-deep-primary--text">{{ paramTypeTitle(param.type) }}</td>
                    </tr>
                </template>
                </tbody>
            </template>
        </card-collection>
    </v-layout>
</div>
