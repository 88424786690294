import * as dater from '@/core/utils/date-helper'
import { filter, IPayload } from '@/core/utils/payload-helper'
import { User, UserPayload } from '@/core/models/user'
import { Stroke, StrokePayload } from '@/core/models/stroke'
import { WorkoutSet, WorkoutSetPayload } from '@/core/models/workout-set'
import { Goal, GoalPayload } from '@/core/models/goal'
import { TrainingProgram, TrainingProgramPayload } from '@/core/models/training-program'

export interface WorkoutPayload {
    id: number
    user_id: number
    stroke_id: number
    name: string
    creator: string
    description: string
    coach_advice: string
    photo_url: string
    level: string
    sport_type: string
    workout_types: string[]
    workout_distance: number
    workout_distance_unit: string
    workout_duration: string
    workout_rest_time: string
    workout_total_time: string
    workout_calories_burned: number
    workout_steps: number
    swimming_location: string
    pool_lengths: number[]
    pool_25: boolean
    executions_count: number
    provider: string
    enabled: boolean
    archived: boolean
    user?: UserPayload
    stroke?: StrokePayload
    sets?: WorkoutSetPayload[]
    programs?: TrainingProgramPayload[]
    workout_goals?: GoalPayload[]
    strokes?: StrokePayload[]
    translations?: {}
}

export class Workout {

    id: number
    userId: number
    strokeId: number
    creator: string
    name: string
    description: string
    coachAdvice: string
    photo?: File
    photoUrl: string
    level: string
    sportType: string
    workoutTypes: string[]
    workoutDistance: number
    workoutDistanceUnit: string
    workoutDuration: string
    workoutRestTime: string
    workoutTotalTime: string
    workoutCaloriesBurned: number
    workoutSteps: number
    swimmingLocation: string
    poolLengths: number[]
    pool25: boolean
    executionsCount: number
    provider: string
    enabled: boolean
    archived: boolean
    user: User
    stroke: Stroke
    sets: WorkoutSet[] = <WorkoutSet[]>[]
    programs: TrainingProgram[] = <TrainingProgram[]>[]
    goals: Goal[] = <Goal[]>[]
    goalIds: number[] = []
    strokes: Stroke[] = <Stroke[]>[]
    strokeIds: number[] = []
    translations?: {} = <any>{}

    constructor(data: WorkoutPayload) {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.creator = data.creator || 'openswim'
        this.name = data.name
        this.description = data.description
        this.coachAdvice = data.coach_advice
        this.photoUrl = data.photo_url
        this.level = data.level
        this.sportType = data.sport_type
        this.workoutTypes = data.workout_types
        this.workoutDistance = data.workout_distance
        this.workoutDistanceUnit = data.workout_distance_unit
        this.workoutDuration = data.workout_duration
        this.workoutRestTime = data.workout_rest_time
        this.workoutTotalTime = data.workout_total_time
        this.workoutCaloriesBurned = data.workout_calories_burned
        this.workoutSteps = data.workout_steps
        this.swimmingLocation = data.swimming_location
        this.poolLengths = data.pool_lengths
        this.pool25 = data.pool_25
        this.executionsCount = data.executions_count
        this.provider = data.provider
        this.enabled = data.enabled
        this.archived = data.archived
        this.user = new User(data.user || <UserPayload>{})
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.capture(data)
    }

    fill(data: WorkoutPayload): this {
        this.id = data.id
        this.userId = data.user_id
        this.strokeId = data.stroke_id
        this.creator = data.creator || 'openswim'
        this.name = data.name
        this.description = data.description
        this.coachAdvice = data.coach_advice
        this.photoUrl = data.photo_url
        this.level = data.level
        this.sportType = data.sport_type
        this.workoutTypes = data.workout_types
        this.workoutDistance = data.workout_distance
        this.workoutDistanceUnit = data.workout_distance_unit
        this.workoutDuration = data.workout_duration
        this.workoutRestTime = data.workout_rest_time
        this.workoutTotalTime = data.workout_total_time
        this.workoutCaloriesBurned = data.workout_calories_burned
        this.workoutSteps = data.workout_steps
        this.swimmingLocation = data.swimming_location
        this.poolLengths = data.pool_lengths
        this.pool25 = data.pool_25
        this.executionsCount = data.executions_count
        this.provider = data.provider
        this.enabled = data.enabled
        this.archived = data.archived
        this.user = new User(data.user || <UserPayload>{})
        this.stroke = new Stroke(data.stroke || <StrokePayload>{})
        this.capture(data)
        return this
    }

    capture(data: WorkoutPayload): this {
        if (data.sets) {
            this.sets = <WorkoutSet[]>[]
            data.sets.map((item) =>  this.sets.push(new WorkoutSet(item)))
        }
        if (data.programs) {
            this.programs = <TrainingProgram[]>[]
            data.programs.map((item) => this.programs.push(new TrainingProgram(item)))
        }
        if (data.strokes) {
            this.strokes = <Stroke[]>[]
            this.strokeIds = <number[]>[]
            data.strokes.map(item => {
                this.strokes.push(new Stroke(item))
                this.strokeIds.push(item.id)
            })
        }
        if (data.workout_goals) {
            this.goals = <Goal[]>[]
            this.goalIds = <number[]>[]
            data.workout_goals.forEach((item: GoalPayload) => {
                this.goals.push(new Goal(item))
                if (item.enabled) {
                    this.goalIds.push(item.id)
                }
            })
        }
        if (data.translations) {
            this.translations = data.translations
        }
        return this
    }

    addSet(): this {
        this.sets.push(new WorkoutSet(<WorkoutSetPayload>{}))
        return this
    }

    delSet(number: number): this {
        const sets = this.sets.map(item => JSON.parse(JSON.stringify(item.payload())))
        sets.splice(number, 1)
        this.sets = []
        sets.map(item => this.sets.push(new WorkoutSet(item)))
        return this
    }

    duplicateSet(number: number): this {
        const set = JSON.parse(JSON.stringify(this.sets[number].payload()))
        set.id = undefined
        this.sets.push(new WorkoutSet(set))
        return this
    }

    updateSetNumber(currentNumber: number, number: number): this {
        const set = JSON.parse(JSON.stringify(this.sets[currentNumber].payload()))
        this.sets.splice(currentNumber, 1)
        const sets = this.sets.map((item: WorkoutSet) => JSON.parse(JSON.stringify(item.payload())))
        sets.splice(number, 0, set)
        this.sets = []
        sets.forEach((item: WorkoutSetPayload) => {
            this.sets.push(new WorkoutSet(item))
        })
        return this
    }

    public get distance(): number {
        let sum = 0
        this.sets.forEach((set: WorkoutSet) => {
            let distance = parseInt(<any>(set.distance || 0))
            if (distance) {
                sum = sum + distance * parseInt(<any>(set.repetitionNumbers || 1))
            }
        })
        return sum
    }

    public get duration(): string {
        let ms = 0
        this.sets.forEach((set: WorkoutSet) => {
            if (set._duration() !== '00:00:00.000') {
                ms = ms + dater.timeStringToMilliseconds(set._duration()) * (set.repetitionNumbers || 1)
            }
        })
        if (ms) {
            return dater.millisecondsToTimeString(ms, true)
        } else {
            return '00:00:00'
        }
    }

    public get totalTime(): string {
        let ms = 0
        this.sets.forEach((set: WorkoutSet) => {
            if (set._duration() !== '00:00:00.000') {
                ms = ms + (dater.timeStringToMilliseconds(set._duration()) + dater.timeStringToMilliseconds(set._restTime())) * (set.repetitionNumbers || 1)
            }
        })
        if (ms) {
            return dater.millisecondsToTimeString(ms, true)
        } else {
            return '00:00:00'
        }
    }

    public get goalsStrTitle(): string {
        let titles = <any>[]
        this.goals.forEach((item: Goal) => {
            if (item.enabled) {
                titles.push(item.name)
            }
        })
        return titles.join(' / ')
    }

    public get strokesStrTitle(): string {
        return this.strokes.map(i => i.name).join(' / ')
    }

    setsPayload(): WorkoutSetPayload[] {
        const sets = <WorkoutSetPayload[]>[]
        this.sets.forEach((item: WorkoutSet) => {
            sets.push(item.payload())
        })
        return sets
    }

    goalsPayload(): GoalPayload[] {
        const goals = <GoalPayload[]>[]
        if (this.goalIds.length) {
            this.goalIds.forEach((id: number) => {
                goals.push(<GoalPayload>{
                    id: id,
                    name: '',
                    sort_order: 0,
                    enabled: true
                })
            })
        } else {
            this.goals.forEach((item: Goal) => {
                if (item.enabled) {
                    goals.push(item.payload())
                }
            })
        }
        return goals
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): this {
        this.fill(<WorkoutPayload>{})
        this.photo = undefined
        this.sets = <WorkoutSet[]>[]
        this.goals = <Goal[]>[]
        this.goalIds = <number[]>[]
        this.strokes = <Stroke[]>[]
        this.strokeIds = <number[]>[]
        this.translations = {}
        return this
    }

    payload(fillable: string[] = []): WorkoutPayload | IPayload {
        let payload = {
            id: this.id,
            user_id: this.userId,
            stroke_id: this.strokeId,
            creator: this.creator,
            name: this.name,
            description: this.description,
            coach_advice: this.coachAdvice,
            level: this.level,
            sport_type: this.sportType,
            workout_types: this.workoutTypes,
            swimming_location: this.swimmingLocation,
            pool_25: this.pool25,
            enabled: this.enabled,
            archived: this.archived,
            sets: this.setsPayload(),
            workout_goals: this.goalsPayload(),
            strokes: this.strokeIds,
            translations: this.translations,
        }
        return filter(payload, fillable)
    }
}
