export interface ParamPayload {
    id: number
    type: string
    param_key: string
    param_value: string
}

export class Param {

    id: number
    type: string
    param_key: string
    param_value: string

    constructor(data: ParamPayload) {
        this.id = data.id
        this.type = data.type
        this.param_key = data.param_key
        this.param_value = data.param_value
    }

    fill(data: ParamPayload): this {
        this.id = data.id
        this.type = data.type
        this.param_key = data.param_key
        this.param_value = data.param_value
        return this
    }

    payload(): ParamPayload {
        return {
            id: this.id,
            type: this.type,
            param_key: this.param_key,
            param_value: this.param_value,
        }
    }
}
